import { COOKIE_CONSENT } from '~/utils/constants'

export const useSentry = () => {
  const sentrySetUserId = () => {
    const { $sentrySetUser, $sentryCaptureException } = useNuxtApp()
    try {
      const { data } = useAuth()
      const userId = data.value?.id
      const cookieConsent = JSON.parse(
        localStorage.getItem(COOKIE_CONSENT) || '{}',
      )
      const analyticalCookiesAccepted = cookieConsent.data?.analyticalCookies

      if (analyticalCookiesAccepted && userId) {
        $sentrySetUser({ id: userId as string })
      }
    } catch (error) {
      $sentryCaptureException(error)
    }
  }

  return {
    sentrySetUserId,
  }
}
