import { $fetchApi } from '~/composables/useFetchApi'

export const faq = {
  namespaced: true,
  state: () => ({
    categories: [],
  }),
  getters: {
    categories(state) {
      return state.categories
    },
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories
    },
    setFAQ(state, { categoryId, faq }) {
      state.categories = state.categories.map((cat) => {
        if (cat.id === categoryId) {
          cat.faq = faq
        }

        return cat
      })
    },
  },
  actions: {
    async getCategories({ commit, state }) {
      if (state.categories.length) return state.categories

      const response = await $fetchApi('/faqcategory')

      commit('setCategories', response.items)

      return response.items
    },

    async getFAQ({ commit }, categoryId) {
      const response = await $fetchApi(`/faqcategory/${categoryId}/faq`)

      commit('setFAQ', {
        categoryId,
        faq: response,
      })

      return response
    },
  },
}
