import { SUBSCRIPTION_CATEGORIES } from '~/utils/constants'
import { $fetchApi } from '~/composables/useFetchApi'
import { useRuntimeConfig } from '#imports'

export const subscriptions = {
  namespaced: true,
  state: () => ({
    products: [],
    cart: {},
    cartIsLoaded: false,
  }),
  getters: {
    products(state) {
      return state.products
    },
    product(state) {
      return (subscriptionTypeId) => {
        return state.products.find(({ id }) => id === subscriptionTypeId)
      }
    },
    cart(state) {
      return state.cart
    },
  },
  mutations: {
    products: (state, products) => {
      if (products && Array.isArray(products)) {
        products.sort((curr, prev) => {
          if (curr.visibilityOrderPosition < prev.visibilityOrderPosition) {
            return -1
          }
          if (curr.visibilityOrderPosition > prev.visibilityOrderPosition) {
            return 1
          }
          return 0
        })
        state.products = products
      }
    },
    setCart: (state, payload) => {
      state.cart = payload
      state.cartIsLoaded = true
    },
    clearCart: (state) => {
      state.cart = {}
      state.cartIsLoaded = false
    },
  },
  actions: {
    async getProducts({ commit, state }) {
      if (state.products.length) return Promise.resolve(state.products)

      const response = await $fetchApi(
        'v1/subscriptions/products?subscriptionCategoryCode=DefaultSubscriptionTypes',
      )

      commit('products', response)

      return response
    },
    async getCart({ commit, state }) {
      if (state.cartIsLoaded) return

      const config = useRuntimeConfig()
      const enableBasicAndPremium = config.public.BASIC_AND_PREMIUM === '1'

      const data = await $fetchApi(
        `/v2/carts/subscriptionCart?enableBasicAndPremium=${enableBasicAndPremium}`,
      )

      const cart = data || {}

      commit('setCart', cart)
    },
    async addToCart({ commit, state }, body) {
      const config = useRuntimeConfig()
      const cartUid = state.cart.uid

      const endpoint = cartUid
        ? `/v2/carts/subscriptionCart/${cartUid}/add`
        : '/v2/proxy/carts/subscriptionCart'

      if (cartUid && body.productUid === state.cart.items[0].itemUid) {
        return
      }

      const response = await $fetchApi(endpoint, {
        method: 'POST',
        body: {
          productTargetType: 'subscription',
          ...body,
          enableBasicAndPremium: config.public.BASIC_AND_PREMIUM === '1',
        },
      })

      if (response) {
        commit('setCart', response)
      }
    },
    async requestUpgrade({ dispatch }) {
      const response = await $fetchApi(
        `v1/subscriptions/products?subscriptionCategoryCode=${SUBSCRIPTION_CATEGORIES.upgrade}`,
      )

      const [product] = response

      await dispatch('addToCart', {
        productUid: product.id,
      })
    },
    async requestDowngrade({ dispatch }) {
      const response = await $fetchApi(
        `v1/subscriptions/products?subscriptionCategoryCode=${SUBSCRIPTION_CATEGORIES.downgrade}`,
      )

      const [product] = response

      await dispatch('addToCart', {
        productUid: product.id,
      })
    },
    async addFailedAutoRenewalSubToCart({ commit }, autoRenewalUid) {
      const response = await $fetchApi(
        `/v2/proxy/carts/autorenewal/${autoRenewalUid}`,
      )

      if (response) {
        commit('setCart', response)
      }
    },
    clearCart({ commit }) {
      commit('clearCart')
    },
  },
}
