import { dateToString } from '~/utils/monthFormatter'
import { ONBOARDING_VARIANTS } from '../utils/constants'

export const onboarding = {
  namespaced: true,
  state: () => ({
    step: 0,
    variant: ONBOARDING_VARIANTS.premium,
  }),
  getters: {
    step(state) {
      return state.step
    },
    variant(state) {
      return state.variant
    },
    label(state) {
      return `onboarding.${state.variant}.step${state.step}`
    },
  },
  mutations: {
    setNextStep: (state, step) => {
      state.step = step
    },
    setVariant: (state, variant) => {
      state.variant = variant
    },
  },
  actions: {
    nextStep({ commit }, step) {
      commit('setNextStep', step)
    },
    async startOnboarding({ commit, rootGetters, dispatch }) {
      const { hasBasicSubscription } = rootGetters
      let variant = ONBOARDING_VARIANTS.premium

      if (hasBasicSubscription) {
        const category = dateToString(new Date())

        let chosenBooks = rootGetters['books/chosenBooks'](category)

        if (!chosenBooks) {
          await dispatch('books/getChosenBooks', category, { root: true })
        }

        chosenBooks = rootGetters['books/chosenBooks'](category)

        variant = chosenBooks.length
          ? ONBOARDING_VARIANTS.basicChosenBook
          : ONBOARDING_VARIANTS.basic
      }

      commit('setVariant', variant)
      commit('setNextStep', 1)
    },
  },
}
