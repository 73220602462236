import { Modal } from 'bootstrap'

export default defineNuxtPlugin(() => ({
  provide: {
    parallel: true,
    bootstrap: {
      Modal,
    },
  },
}))
