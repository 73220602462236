import { $fetchApi } from '~/composables/useFetchApi'

export const shop = {
  namespaced: true,
  state: () => ({
    shopEbooks: [],
    shopEbookDetails: {},
    shopPurchases: {},
    shopGenres: [],
    pagination: {
      currentPageIndex: 1,
      totalAmountOfBooks: 0,
      totalNumberOfPages: 1,
    },
  }),
  getters: {
    shopEbooks(state) {
      return state.shopEbooks
    },
    shopEbookDetails(state) {
      return (slug) => {
        if (state.shopEbookDetails[slug]) {
          return state.shopEbookDetails[slug]
        }
        return {}
      }
    },
    shopPurchases(state) {
      return state.shopPurchases.items
    },
    shopPurchasesTotalPages(state) {
      return state.shopPurchases.totalPages
    },
    currentPage(state) {
      return state.shopPurchases.currentPage || 0
    },
  },
  mutations: {
    shopEbooks(state, books) {
      if (books) {
        state.shopEbooks = books
      }
    },
    shopEbookDetails(state, { slug, ...restBook }) {
      state.shopEbookDetails[slug] = restBook
    },
    shopPurchases(state, data) {
      state.shopPurchases = data
    },
  },
  actions: {
    async getShopEbooks({ commit }, params) {
      const response = await $fetchApi('/v4/books/shop/monthly-books', {
        query: params,
      })
      const { books, ...pagination } = response

      if (books) {
        commit('shopEbooks', books)
      }

      return { books, pagination }
    },
    async getShopGenres() {
      const response = await $fetchApi('/v3/books/shop/monthly-books/genres')

      if (response) {
        const genres = response.map((filter) => {
          return {
            ...filter,
            label: filter.name,
            uid: filter.id,
            id: filter.name.replaceAll(' ', '').toLowerCase(),
          }
        })

        return genres
      }
    },
    async getRelatedBooks(_, slug) {
      const response = await $fetchApi(
        `books/relatedShopBooks?slug=${slug}&numberOfRelatedBooks=8`,
      )

      return response.books
    },
    async getShopPurchases({ commit }, page) {
      const response = await $fetchApi(
        `v3/books/shop/purchased?pageIndex=${page - 1}&pageSize=20`,
      )
      commit('shopPurchases', response)
    },
    async getShopBookDetail({ commit, state }, slug) {
      const detailsInState = state.shopEbookDetails[slug]

      if (detailsInState) {
        return {
          ...detailsInState,
          slug,
        }
      }

      const response = await $fetchApi('/proxy/books/shop/details', {
        params: { slug },
      })
      const book = {
        ...response,
        slug,
      }

      commit('shopEbookDetails', book)

      return book
    },
  },
}
