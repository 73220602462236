export const PROMOTION_VALIDATION_ERRORS = [
  'promotions.promotionNotFound',
  'promotions.userReachMaxRedeemableTimesOfPromotion',
  'promotions.promotionCodeReachedMaximumOfRedeemTimes',
  'promotions.codeExpired',
  'promotions.campaignNotStarted',
  'promotions.genericValidationError',
  'promotions.hasCurrentSubscription',
  'promotions.hasFutureSubscription',
  'promotions.hasPausedSubscription',
  'promotions.hasAutoRenewalForToday',
  'promotions.codesInvalidStartInterval',
  'promotions.codesInvalidPrefix',
]

export const BACKEND_ERRORS = [
  'emailAlreadyExists',
  'invalidCredentials',
  'userNotFound',
  'users.userValidation.invalidEmail',
]

export const CART_VALIDATION_ERRORS = [
  'cart.scheduledAutoRenewal',
  'cart.hasFutureSubscription',
  'cart.hasPausedSubscription',
  'cart.hasAutoRenewalForToday',
  'cart.hasPaidTrialSubscription',
  'subscription.notAllowUpgradeWithActiveAutoRenewal',
]

export const CHOOSE_BOOK_ERRORS = [
  'books.userDoesNotHaveMoreBooksToChose',
  'books.userBookAlreadyChosen',
]

export const EMAIL_DOMAINS = [
  'gmail.com',
  'ziggo.nl',
  'home.nl',
  'kpnmail.nl',
  'upcmail.nl',
  'hetnet.nl',
  'planet.nl',
  'icloud.com',
  'telenet.be',
  'casema.nl',
  'xs4all.nl',
  'quicknet.nl',
  'chello.nl',
  'kpnplanet.nl',
  'me.com',
  'zeelandnet.nl',
  'telfort.nl',
  'zonnet.nl',
  'online.nl',
  'skynet.be',
  'hccnet.nl',
  'tele2.nl',
  'kabelfoon.nl',
  'aol.com',
  'versatel.nl',
  'solcon.nl',
  'onsbrabantnet.nl',
  'wxs.nl',
  'tiscali.nl',
  'freeler.nl',
  't-mobilethuis.nl',
]

export const EMAIL_SECOND_LEVEL_DOMAINS = [
  'hotmail',
  'live',
  'outlook',
  'yahoo',
  'msn',
  'caiway',
]

export const EMAIL_TOP_LEVEL_DOMAINS = ['nl', 'com', 'be', 'net']

export const COOKIE_CONSENT = 'cookie-consent'

export const PROMO_CODE_STORAGE_KEY = 'promo-code'

export const CREDIT_CARDS = {
  visadankort: 'Visa Dankort',
  visa: 'Visa',
  mc: 'Mastercard',
  maestro: 'Maestro',
  bijcard: 'de Bijenkorf Credit Card',
  bcmc: 'Bancontact',
  amex: 'American Express',
}

export const TRACKING_LISTS = {
  shop: {
    itemListId: 'shop',
    itemListName: 'Shop',
  },
  promo: {
    itemListId: 'promo',
    itemListName: 'Promo',
  },
  defaultSub: {
    itemListId: 'default_subscriptions',
    itemListName: 'Default subscriptions',
  },
}

export const ONBOARDING_VARIANTS = {
  premium: 'premium',
  basicChosenBook: 'basicChosenBook',
  basic: 'basic',
}

export const END_DATE = {
  endOfTerm: 'endOfTerm',
  direct: 'direct',
}

export const CANCEL_SCREENS = {
  pause: 'PauseScreen',
  reason: 'ReasonScreen',
  selection: 'SelectionScreen',
  endDate: 'EndDateScreen',
  success: 'SuccessScreen',
}

export const PAGES = {
  payment: 'betalen',
  subscriptionChangeOverview: 'overzicht',
  chooseSubscription: '/keuze',
  subscriptionPurchaseSuccess: 'gelukt',
  shop: '/shop',
  register: '/aanmelden',
  archive: '/archief',
  monthlyBooks: '/boeken',
  redeemPageSlug: 'verzilver',
  redeemPagePath: '/promo/verzilver',
}

export const CART_PAYMENT_TYPES = {
  regularPayment: 'RegularPayment',
  freePayment: 'FreePayment',
  upgradeSubscriptionRegularPayment: 'UpgradeSubscriptionRegularPayment',
  subscriptionUpdateFreePayment: 'SubscriptionUpdateFreePayment',
  subscriptionUpdateVerificationPayment:
    'SubscriptionUpdateVerificationPayment',
  subscriptionUpdateFreePaymentAndRefund:
    'SubscriptionUpdateFreePaymentAndRefund',
}

export const SUBSCRIPTION_CATEGORIES = {
  default: 'DefaultSubscriptionTypes',
  upgrade: 'UpgradeSubscriptionTypes',
  downgrade: 'DowngradeSubscriptionTypes',
}

export const UTM_COOKIES = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]

export const GENRES_COLORS = {
  feelgood: {
    light: '#dfeec7',
    bright: '#93c543',
    dark: '#678a2f',
  },
  thriller: {
    light: '#fae9c1',
    bright: '#eeb730',
    dark: '#a78022',
  },
  roman: {
    light: '#f7dbd4',
    bright: '#e68671',
    dark: '#a15e4f',
  },
  'literaire-roman': {
    light: '#ebdfe9',
    bright: '#bc96b7',
    dark: '#846980',
  },
  'non-fictie': {
    light: '#d1dbee',
    bright: '#6787c7',
    dark: '#485f8b',
  },
  'fantasy--sciencefiction': {
    light: '#ceede4',
    bright: '#5bc4a6',
    dark: '#408974',
  },
  'historische-roman': {
    light: '#fef7d4',
    bright: '#fbe56f',
    dark: '#b0a04e',
  },
  'young-adult': {
    light: '#c7e8f5',
    bright: '#44B1DF',
    dark: '#307c9c',
  },
}
