import { $fetchApi } from '~/composables/useFetchApi'

const getInitialState = () => ({
  totalItems: 0,
  cartLoaded: false,
  isLoading: false,
  data: {},
  removingItem: false,
  paymentSessionDetails: {},
  invalidCartItems: [],
})

export const shopCart = {
  namespaced: true,
  state: () => getInitialState(),
  getters: {
    totalItems(state) {
      return state.totalItems
    },
    cartLoaded(state) {
      return state.cartLoaded
    },
    cartData(state) {
      return state.data
    },
    removingItem(state) {
      return state.removingItem
    },
    isLoading(state) {
      return state.isLoading
    },
    paymentSession(state) {
      return state.paymentSessionDetails.paymentSession
    },
    paymentSessionId(state) {
      return state.paymentSessionDetails.paymentSessionId
    },
    orderNumber(state) {
      return state.paymentSessionDetails.orderNumber
    },
    invalidCartItems(state) {
      return state.invalidCartItems
    },
  },
  mutations: {
    setIsLoading: (state, isLoading) => {
      state.isLoading = isLoading
    },
    setCart: (state, cart) => {
      state.data = cart
      state.totalItems = cart.items.length
      state.cartLoaded = true
    },
    setEmptyCart: (currentState) => {
      Object.assign(currentState, getInitialState())
    },
    setRemovingItem: (state, removing) => {
      state.removingItem = removing
    },
    setPaymentSessionDetails: (state, paymentSessionDetails) => {
      state.paymentSessionDetails = paymentSessionDetails
      state.invalidCartItems = []
    },
    setInvalidCartItems: (state, invalidCartItems) => {
      state.invalidCartItems = invalidCartItems
    },
  },
  actions: {
    async getCart({ commit, state }) {
      try {
        if (state.isLoading) return
        commit('setIsLoading', true)

        const response = await $fetchApi('/nbi/proxy/carts/shopmulticart')
        commit('setCart', response)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          commit('setCart', { items: [] })
        } else {
          throw error
        }
      } finally {
        commit('setIsLoading', false)
      }
    },
    async addToCart({ commit }, { cartUid, productUid, price }) {
      const response = await $fetchApi('/nbi/proxy/carts/shopmulticart/add', {
        method: 'PUT',
        body: {
          productUid,
          productTargetType: 'Book',
          price,
          currency: 'EUR',
          cartUid,
        },
      })
      commit('setCart', response)
    },
    async removeFromCart({ commit }, { cartUid, productUid }) {
      try {
        commit('setRemovingItem', true)

        const response = await $fetchApi(
          '/nbi/proxy/carts/shopmulticart/remove',
          {
            method: 'PUT',
            body: { cartUid, productUid },
          },
        )

        commit('setCart', response)
      } finally {
        commit('setRemovingItem', false)
      }
    },
    clearCart({ commit }) {
      commit('setEmptyCart')
    },
    async createPaymentSession({ commit }, body) {
      commit('payment/setPurchasedItems', {}, { root: true })

      const response = await $fetchApi(
        '/proxy/v2/carts/shopmulticart/checkout',
        { method: 'POST', body },
      )
      if (response.invalidCartItems.length) {
        commit('setInvalidCartItems', response.invalidCartItems)
      } else if (response.paymentSessionDetails) {
        commit('setPaymentSessionDetails', response.paymentSessionDetails)
      }
    },
    resetPaymentSession({ commit }) {
      commit('setPaymentSessionDetails', {})
    },
  },
}
