/* eslint-disable no-empty */

// custom page transition implementation because default one is not working nicely with useAsyncData
// (transition is happening only after the next page data is fetched instead of on navigation change. Check in the documentation under known issues: https://nuxt.com/docs/getting-started/transitions)
// and there is a bug with onMounted hook if transition feature turned is on https://github.com/nuxt/nuxt/issues/13471

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:start', () => {
    try {
      const layout = document.querySelector('body')
      layout?.classList.remove('page--finish')
      layout?.classList.add('page--start')
    } catch (err) {}
  })
  nuxtApp.hook('page:finish', () => {
    try {
      const layout = document.querySelector('body')
      layout?.classList.add('page--finish')

      setTimeout(() => {
        layout?.classList.remove('page--start')
      }, 300)
    } catch (err) {}
  })
})
