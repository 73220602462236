export const route = {
  namespaced: true,
  state: () => ({
    from: undefined,
  }),
  getters: {
    from(state) {
      return state.from
    },
  },
  mutations: {
    setFrom(state, data) {
      state.from = data
    },
  },
}
