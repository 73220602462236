import createMultiTabState from 'vuex-multi-tab-state'
import { labels } from './labels'
import { drawer } from './drawer'
import { shopCart } from './shopCart'
import { user } from './user'
import { toast } from './toast'
import { subscriptions } from './subscriptions'
import { books } from './books'
import { onboarding } from './onboarding'
import { reviews } from './reviews'
import { account } from './account'
import { route } from './route'
import { booksProgress } from './booksProgress'
import { shop } from './shop'
import { payment } from './payment'
import { promotion } from './promotion'
import { blog } from './blog'
import { faq } from './faq'

export const plugins =
  process.client && !process.env.VITEST
    ? [
        createMultiTabState({
          statesPaths: [
            'shopCart.data',
            'shopCart.totalItems',
            'shopCart.removingItem',
            'subscriptions.cart',
            'payment.purchasedItems',
            // 'booksProgress.items', sync does not work properly, we need to enable this once we move to Pinia
          ],
          key: 'bc-vuex',
        }),
      ]
    : undefined

export const modules = {
  labels,
  toast,
  user,
  drawer,
  books,
  shopCart,
  subscriptions,
  onboarding,
  reviews,
  account,
  route,
  booksProgress,
  payment,
  shop,
  promotion,
  blog,
  faq,
}
