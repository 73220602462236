export default defineNuxtPlugin(() => {
  const { $store } = useNuxtApp()

  return {
    parallel: true,
    provide: {
      toast: {
        show(message: string, options: object) {
          $store.dispatch('addToastMessage', {
            duration: 4000,
            ...options,
            message,
            type: 'info',
          })
        },
        success(message: string, options: object) {
          $store.dispatch('addToastMessage', {
            duration: 4000,
            ...options,
            message,
            type: 'success',
          })
        },
        warning(message: string, options: object) {
          $store.dispatch('addToastMessage', {
            ...options,
            message,
            type: 'warning',
          })
        },
        error(message: string, options: object) {
          $store.dispatch('addToastMessage', {
            ...options,
            message,
            type: 'danger',
          })
        },
      },
    },
  }
})
