export const dutchMonths = [
  'januari',
  'februari',
  'maart',
  'april',
  'mei',
  'juni',
  'juli',
  'augustus',
  'september',
  'oktober',
  'november',
  'december',
]

export const transformMonthFromReadable = (month) => {
  const readableMonthPattern = new RegExp(
    `^(${dutchMonths.join('|')})-(\\d{4})$`,
    'ig',
  )
  return (
    month &&
    month.replace(readableMonthPattern, (match, month, year) => {
      const numMonth = (dutchMonths.indexOf(month) + 1)
        .toString()
        .padStart(2, 0)
      return `${year}-${numMonth}`
    })
  )
}

export const transformMonthFromDate = (yearAndMonth) => {
  if (!yearAndMonth) return yearAndMonth

  const [year, monthString] = yearAndMonth.split('-')

  if (!year || !monthString) return yearAndMonth

  const month = parseInt(monthString)
  const monthIndex = month - 1
  const monthName = dutchMonths[monthIndex]

  return `${monthName}-${year}`
}

export const dateToString = (date) =>
  `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`
