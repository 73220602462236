import { $fetchApi } from '~/composables/useFetchApi'

export const getDefaultState = () => ({
  items: [],
  updatingProgress: false,
})

export const booksProgress = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    bookProgress(state) {
      return (slug) => {
        if (!state.items) return null

        return state.items.find((book) => book.bookSlug === slug)
      }
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = [...state.items, ...items]
    },
    addItem(state, item) {
      state.items = [item, ...state.items]
      state.updatingProgress = false
    },
    updateItem(state, updatedItem) {
      state.items = [
        ...state.items.map((item) =>
          item.id !== updatedItem.id ? item : { ...item, ...updatedItem },
        ),
      ]
      state.updatingProgress = false
    },
    setUpdatingProgress(state, updatingProgress) {
      state.updatingProgress = updatingProgress
    },
    setEmptyProgress: (currentState) => {
      Object.assign(currentState, getDefaultState())
    },
  },
  actions: {
    async getBookProgress({ commit, getters }, { userId, bookSlug }) {
      const stateBookProgress = getters.bookProgress(bookSlug)

      if (stateBookProgress) {
        return stateBookProgress
      }

      const response = await $fetchApi(
        `/book/progress?userId=${userId}&bookSlug=${bookSlug}`,
      )

      if (response.items.length) {
        commit('addItem', response.items[0])
      }

      return response
    },
    async getSelectionProgress({ commit }, { userId, catalog, category }) {
      const response = await $fetchApi(
        `/book/progress?userId=${userId}&catalog=${catalog}&category=${category}`,
      )

      commit('setItems', response.items)
    },
    async createBookProgress({ commit, state }, data) {
      if (state.updatingProgress) return

      commit('setUpdatingProgress', true)

      const response = await $fetchApi('/book/progress', {
        method: 'POST',
        body: data,
      })

      commit('addItem', response)
    },
    async updateBookProgress({ commit, state }, data) {
      if (state.updatingProgress) return

      commit('setUpdatingProgress', true)

      const response = await $fetchApi('/book/progress', {
        method: 'PUT',
        body: data,
      })

      commit('updateItem', response)
    },
    clearBooksProgress({ commit }) {
      commit('setEmptyProgress')
    },
  },
}
