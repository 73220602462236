<script setup>
import { useStore } from 'vuex'

const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
})

const store = useStore()
const { $sentryCaptureException, $label } = useNuxtApp()
const { data } = useAuth()

const { error: asyncDataError } = await useAsyncData(() => {
  // workaround not to call twice getLabels
  if (!props.error.__nuxt_error) {
    store.commit('setUser', data.value)
    return store.dispatch('getLabels')
  }
})

if (asyncDataError.value) {
  $sentryCaptureException(asyncDataError.value)
}

const title = computed(() => {
  return props.error.statusCode === 404
    ? $label('common.404error.title')
    : $label('common.error.generalTitle')
})
const text = computed(() => {
  return props.error.statusCode === 404
    ? $label('common.404error.subTitle')
    : ''
})

useHead({
  title: `Bookchoice • ${title.value}`,
})

onMounted(() => {
  $sentryCaptureException(props.error)
})
</script>

<template>
  <NuxtLayout name="default">
    <ErrorPageContent :title="title" :text="text" />
  </NuxtLayout>
</template>
