import { v4 as uuidv4 } from 'uuid'

export const toast = {
  state: () => ({
    message: null,
  }),
  getters: {
    toastMessage(state) {
      return state.message
    },
  },
  mutations: {
    setToastMessage(state, payload) {
      state.message = payload
    },
  },
  actions: {
    dismissToastMessage({ commit }) {
      commit('setToastMessage', null)
    },
    addToastMessage({ commit }, payload) {
      commit('setToastMessage', {
        ...payload,
        id: uuidv4(),
      })
    },
  },
}
