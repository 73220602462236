import { $fetchApi } from '~/composables/useFetchApi'

export const promotion = {
  namespaced: true,
  state: () => ({
    landingPage: {},
    promoCode: '',
    promotion: {},
    isValid: false,
  }),
  getters: {
    landingPage(state) {
      return state.landingPage
    },
    isPromoCodeValid(state) {
      return state.isValid
    },
    promotion(state) {
      return {
        ...state.promotion,
        promoCode: state.promoCode,
      }
    },
  },
  mutations: {
    setLandingPage(state, landingPage) {
      if (landingPage) {
        state.landingPage = landingPage
      }
    },
    setPromotionCode(state, promoCode) {
      state.promoCode = promoCode
    },
    setPromotionValidity(state, isValid) {
      state.isValid = isValid
    },
    setPromotion(state, payload) {
      state.promotion = payload
    },
  },
  actions: {
    async getLandingPage({ commit }, slug) {
      const response = await $fetchApi(`/nbi/proxy/landingpages/slug/${slug}`)
      commit('setLandingPage', response)

      return response
    },

    async validatePromoCode({ commit }, { promotionCode, isAuthenticated }) {
      commit('setPromotionValidity', false)

      const endpoint = isAuthenticated
        ? '/nbi/proxy/promotions/uservalidate'
        : '/nbi/proxy/promotions/validate'

      const response = await $fetchApi(endpoint, {
        method: 'POST',
        body: { promotionCode },
      })

      commit('setPromotionCode', promotionCode)
      commit('setPromotionValidity', true)
      commit('setPromotion', response)

      return response
    },

    setPromotionValidity({ commit }, isValid) {
      commit('setPromotionValidity', isValid)
    },

    setPromotionCode({ commit }, promoCode) {
      commit('setPromotionCode', promoCode)
    },
  },
}
