import { $fetchApi } from '~/composables/useFetchApi'
import { useRuntimeConfig } from '#imports'

export const getDefaultState = () => ({
  isFetchingPaymentSession: false,
  paymentSessionData: {},
  orderNumber: null,
  paymentMethod: null,
  purchasedItems: {},
  purchasedItemsAmount: null,
  refund: {},
})

export const payment = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    paymentSession(state) {
      return state.paymentSessionData.paymentSession
    },
    paymentSessionId(state) {
      return state.paymentSessionData.paymentSessionId
    },
    orderNumber(state) {
      return state.paymentSessionData.orderNumber
    },
    paymentSessionAmount(state) {
      return state.paymentSessionData.amount || 0
    },
    isFetchingPaymentSession(state) {
      return state.isFetchingPaymentSession
    },
    paymentMethod(state) {
      return state.paymentMethod
    },
    purchasedSubscription(state) {
      return state.purchasedItems.purchasedSubscriptionProduct
    },
    purchasedItemsAmount(state) {
      return state.purchasedItems.amount
    },
    shopPurchase(state) {
      return state.purchasedItems.books
    },
    amountToBeRefunded(state) {
      return state.refund.amountToBePaid
    },
    monthsToBeRefunded(state) {
      return state.refund.monthsToBeRefunded
    },
  },
  mutations: {
    setIsFetchingPaymentSession(state, isFetching) {
      state.isFetchingPaymentSession = isFetching
    },
    setPaymentSessionData(state, paymentSessionData) {
      state.paymentSessionData = paymentSessionData
    },
    setPaymentMethod(state, paymentMethod) {
      state.paymentMethod = paymentMethod
    },
    setPurchasedItems(state, purchasedItems) {
      state.purchasedItems = purchasedItems
    },
    setEmptyPaymentSession(currentState) {
      Object.assign(currentState, getDefaultState())
    },
    setRefundInfo(state, data) {
      state.refund = data
    },
  },
  actions: {
    async getPaymentSession({ commit }, params) {
      const config = useRuntimeConfig()
      commit('setIsFetchingPaymentSession', true)
      commit('setPurchasedItems', {})

      const response = await $fetchApi('/v4/payments/session', {
        method: 'POST',
        body: {
          ...params,
          isShop: false,
          enableBasicAndPremium: config.public.BASIC_AND_PREMIUM === '1',
        },
      })

      commit('setPaymentSessionData', response)
      commit('setIsFetchingPaymentSession', false)
    },

    async getSessionData(_, sessionId) {
      const response = await $fetchApi(
        `/v3/payments/session?sessionId=${sessionId}`,
      )

      return response.paymentSession
    },

    async completePayment({ commit }, { orderNumber }) {
      commit('setPurchasedItems', {})

      const response = await $fetchApi('/v4/payments/success', {
        method: 'POST',
        body: { orderNumber },
      })

      if (response) {
        commit('setPurchasedItems', response)
      }
    },

    async getRefundInfo({ commit }) {
      const response = await $fetchApi(`payments/v1/refund/prediction`)

      if (response) {
        commit('setRefundInfo', response)
      }
    },

    setPaymentMethod({ commit }, paymentMethod) {
      commit('setPaymentMethod', paymentMethod)
    },

    clearPaymentSession({ commit }) {
      commit('setEmptyPaymentSession')
    },
  },
}
