import { $fetchApi } from '~/composables/useFetchApi'
import { useNuxtApp } from '#imports'

export const setSubEndDate = (sub) => {
  return {
    ...sub,
    endDate: sub.offboardingDate || sub.endDate,
    originalEndDate: sub.endDate,
  }
}

const getInitialState = () => ({
  currentSubscription: null,
  pastSubscriptions: {},
  futureSubscriptions: [],
  subscriptions: [],
  newsletterOptions: [],
  paymentProfile: null,
  canPauseSubscription: false,
  remainingPause: 1,
  paymentSession: null,
  validatingPayment: false,
})

export const account = {
  namespaced: true,
  state: () => getInitialState(),
  getters: {
    currentSubscription(state) {
      return state.currentSubscription
    },
    pastSubscriptions(state) {
      return state.pastSubscriptions
    },
    futureSubscriptions(state) {
      return state.futureSubscriptions.reverse()
    },
    newsletterOptions(state) {
      return state.newsletterOptions
    },
    paymentProfile(state) {
      return state.paymentProfile
    },
    canPauseSubscription(state) {
      return state.canPauseSubscription
    },
    remainingPause(state) {
      return state.remainingPause
    },
    paymentSession(state) {
      return state.paymentSession
    },
    validatingPayment(state) {
      return state.validatingPayment
    },
  },
  mutations: {
    setCurrentSubscription: (state, data) => {
      state.currentSubscription = setSubEndDate(data)
    },
    setPastSubscriptions: (state, data) => {
      state.pastSubscriptions = {
        ...data,
        items: data.items.map((sub) => setSubEndDate(sub)),
      }
    },
    setFutureSubscriptions: (state, subs) => {
      state.futureSubscriptions = subs.map((sub) => setSubEndDate(sub))
    },
    setNewsletterOptions: (state, options) => {
      state.newsletterOptions = options
    },
    setPaymentProfile: (state, paymentProfile) => {
      state.paymentProfile = paymentProfile
    },
    setDefaultState: (currentState) => {
      Object.assign(currentState, getInitialState())
    },
    setPauseInformation: (state, { isValidUserToBePaused, remainingPause }) => {
      state.canPauseSubscription = isValidUserToBePaused
      state.remainingPause = remainingPause
    },
    setPaymentSession: (state, paymentSession) => {
      state.paymentSession = paymentSession
    },
    setValidatingPayment: (state, value) => {
      state.validatingPayment = value
    },
  },
  actions: {
    async signUp(_, { email, firstName, lastName, password }) {
      const body = {
        email,
        firstName,
        lastName,
        password,
        country: 'nl',
      }

      await $fetchApi('v3/member/signup', {
        method: 'POST',
        body,
      })
    },
    async recoverPassword(_, email) {
      await $fetchApi('/member/forgotpassword', {
        method: 'POST',
        body: { email },
      })
    },
    async resetPassword(_, { email, token, newPassword }) {
      const body = {
        email,
        token,
        newPassword,
      }

      await $fetchApi('/member/resetpassword', {
        method: 'POST',
        body,
      })
    },
    async updateProfile({ commit }, profile) {
      const response = await $fetchApi('/v4/member/profile', {
        method: 'PUT',
        body: profile,
      })

      commit('setUser', response, { root: true })
    },
    async updateProfilePhoto({ commit }, photo) {
      const formData = new FormData()
      formData.append('photo', photo)

      const response = await $fetchApi('/member/photo', {
        method: 'POST',
        body: formData,
      })

      commit('setUserPhoto', response.photo, { root: true })
    },
    async updateNewsletterOptions({ commit }, options) {
      const response = await $fetchApi('/nbi/member/newsletteroptions', {
        method: 'PUT',
        body: options,
      })

      commit('setNewsletterOptions', response)
    },
    async getPaymentProfile({ commit }, userUid) {
      const { $sentryCaptureException } = useNuxtApp()
      try {
        const response = await $fetchApi(
          `/user/${userUid}/defaultPaymentProfile`,
        )

        commit('setPaymentProfile', response || null)
      } catch (error) {
        $sentryCaptureException(error)
      }
    },
    async getNewsletterOptions({ commit }) {
      const response = await $fetchApi('/nbi/member/newsletteroptions')

      commit('setNewsletterOptions', response)

      return response
    },
    async getCurrentSubscription({ commit }) {
      const response = await $fetchApi(
        '/usersubscriptions/valid/subscriptions?period=current',
      )

      const currentSubscription = response.items[0]

      if (currentSubscription) {
        commit('setCurrentSubscription', currentSubscription)
      }
    },
    async getPastSubscriptions({ commit }, page) {
      const response = await $fetchApi(
        `/usersubscriptions/valid/subscriptions?period=historical&PageSize=6&PageIndex=${page}`,
      )

      commit('setPastSubscriptions', response)
    },
    async getFutureSubscriptions({ commit }) {
      const response = await $fetchApi(
        '/usersubscriptions/valid/subscriptions?period=future',
      )

      commit('setFutureSubscriptions', response.items)
    },
    async getPauseInformation({ commit }, userId) {
      const response = await $fetchApi(
        `users/${userId}/autorenewal/membershipPauseHistories/RemainingPause`,
      )

      commit('setPauseInformation', response)
    },
    async pauseSubscription({ dispatch }, { userId, months }) {
      await $fetchApi(
        `orders/users/${userId}/autorenewal/membershipPauseHistories`,
        { method: 'PUT', body: { userId, operation: 'pause', months } },
      )

      await dispatch('getCurrentSubscription')
      await dispatch('updateUserData', undefined, { root: true })
      await dispatch('getPauseInformation', userId)
    },
    async cancelSubscription({ dispatch }, { userId, cancelEndOfTerm }) {
      await $fetchApi(`/v3/user/userSubscription/cancel`, {
        method: 'PUT',
        body: { cancelEndOfTerm },
      })

      await dispatch('getCurrentSubscription')
      await dispatch('getFutureSubscriptions')
      await dispatch('updateUserData', undefined, { root: true })
      await dispatch('getPauseInformation', userId)
    },
    async resumeSubscription({ dispatch }, { userId }) {
      await $fetchApi(
        `orders/users/${userId}/autorenewal/membershipPauseHistories`,
        {
          method: 'PUT',
          body: {
            userId,
            operation: 'resume',
          },
        },
      )

      await dispatch('getCurrentSubscription')
      await dispatch('updateUserData', undefined, { root: true })
      await dispatch('getPauseInformation', userId)
    },
    async getUpdatePaymentMethodSession({ commit }) {
      commit('setPaymentSession', null)
      const { protocol, host } = window.location

      const response = await $fetchApi('/v4/payment/paymentMethodSession', {
        method: 'POST',
        body: {
          origin: `${protocol}//${host}`,
          returnUrl: `${protocol}//${host}/account`,
        },
      })

      commit('setPaymentSession', response)
    },
    setValidatingPayment({ commit }, value) {
      commit('setValidatingPayment', value)
    },
    clearAccountCredentials({ commit }) {
      commit('setDefaultState')
    },
  },
}
