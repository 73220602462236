import { defineRule, configure } from 'vee-validate'
import { required, email, min, is, regex } from '@vee-validate/rules'

defineRule('required', required)
defineRule('email', email)
defineRule('min', min)
defineRule('is', is)
defineRule('regex', regex)

export default defineNuxtPlugin(() => {
  const { $label } = useNuxtApp()

  configure({
    generateMessage: (context) => {
      switch (context.rule?.name) {
        case 'email':
          return $label(`messages.${context.field}Format`, undefined)
        case 'required':
          return $label(`messages.${context.field}Required`, undefined)
        case 'min':
          return $label(`messages.${context.field}TooShort`, undefined)
        case 'is':
          return $label(`messages.${context.field}NotCorrect`, undefined)
        case 'regex':
          return $label(`messages.${context.field}Invalid`, undefined)
        default:
          return $label(`The field ${context.field} is invalid`, undefined)
      }
    },
  })
})
