import { $fetchApi } from '~/composables/useFetchApi'

export const labels = {
  state: () => ({
    data: {},
  }),
  getters: {
    labels(state) {
      return state.data
    },
  },
  mutations: {
    setLabels(state, data) {
      if (data) {
        state.data = data
      }
    },
  },
  actions: {
    async getLabels({ commit }) {
      const response = await $fetchApi('/labels')

      if (response) {
        const labels = JSON.parse(response)
        commit('setLabels', labels)
      }

      return response
    },
  },
}
