import { encrypt, decrypt } from '~/utils/crypto'
import { defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin(() => {
  return {
    parallel: true,
    provide: {
      crypt: {
        encrypt,
        decrypt,
      },
    },
  }
})
