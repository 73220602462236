import { $fetchApi } from '~/composables/useFetchApi'

export const getDefaultState = () => ({
  items: [],
  reviewSubmitted: false,
  bookSlug: '',
  totalAmountOfRatings: 0,
  averageRating: 0,
  totalPages: 1,
  currentPage: 1,
  sort: 'CreateDate',
  sortDirection: 'Desc',
  userAlreadyWroteReview: false,
})

export const reviews = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    reviews(state) {
      return state.items
    },
    reviewSubmitted(state) {
      return state.reviewSubmitted
    },
    totalAmountOfRatings(state) {
      return state.totalAmountOfRatings
    },
    averageRating(state) {
      return state.averageRating
    },
    totalPages(state) {
      return state.totalPages
    },
    currentPage(state) {
      return state.currentPage
    },
    sort(state) {
      return state.sort
    },
    sortDirection(state) {
      return state.sortDirection
    },
    userAlreadyWroteReview(state) {
      return state.userAlreadyWroteReview
    },
    bookSlug(state) {
      return state.bookSlug
    },
  },
  mutations: {
    setReviews: (state, reviewsData) => {
      Object.assign(state, reviewsData)
    },
    setReviewSubmitted: (state, submitted) => {
      state.reviewSubmitted = submitted
    },
  },
  actions: {
    async submitReview({ commit }, data) {
      await $fetchApi('/v2/reviews', {
        method: 'POST',
        body: data,
      })

      commit('setReviewSubmitted', true)
    },
    async getReviews(
      { commit },
      { bookSlug, sort, sortDirection, pageIndex, pageSize = 3 },
    ) {
      const response = await $fetchApi(
        `/reviews/?bookSlug=${bookSlug}&PageSize=${pageSize}&PageIndex=${pageIndex}&Sort=${sort}&SortDirection=${sortDirection}`,
      )

      commit('setReviews', {
        ...response,
        sort,
        sortDirection,
        bookSlug,
      })
    },
    async likeReview(_, reviewUid) {
      await $fetchApi(`/reviews/${reviewUid}/reactions`, {
        method: 'POST',
        body: JSON.stringify('heart'),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    async removeLike(_, reviewUid) {
      await $fetchApi(`/reviews/${reviewUid}/reactions`, { method: 'DELETE' })
    },
    clearReviews({ commit }) {
      commit('setReviews', getDefaultState())
    },
    async reportReview(_, { reviewUid, reason }) {
      await $fetchApi(`/reviews/${reviewUid}/inappropriateVotes`, {
        method: 'POST',
        body: JSON.stringify(reason),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    async deleteReview({ commit }, reviewUid) {
      await $fetchApi(`/reviews/${reviewUid}`, {
        method: 'DELETE',
      })

      commit('setReviewSubmitted', false)
    },
  },
}
