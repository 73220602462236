import { useAuth, useRuntimeConfig } from '#imports'

export const user = {
  state: () => ({
    data: false,
  }),
  getters: {
    loggedInUser(state) {
      return state.data
    },
    isAuthenticated(state) {
      return !!state.data
    },
    canBuySubscription(state) {
      if (!state.data) return true

      const { scheduledAutoRenewal, hasFutureSubscriptions } = state.data

      return !scheduledAutoRenewal && !hasFutureSubscriptions
    },
    canUpgradeToPremium(state) {
      const config = useRuntimeConfig()

      return (
        config.public.BASIC_AND_PREMIUM === '1' && !!state.data?.isAbleToUpgrade
      )
    },
    hasValidSubscription(state) {
      return !!state.data?.currentMonthSubscription
    },
    hasAutoRenewalSubscription(state) {
      const user = state.data

      return (
        user &&
        user.currentMonthSubscription &&
        user.currentMonthSubscription.isAutoRenewal
      )
    },
    hasBasicSubscription(state) {
      const config = useRuntimeConfig()
      const featureLive = config.public.BASIC_AND_PREMIUM === '1'
      const user = state.data

      return !!(
        featureLive &&
        user &&
        user.currentMonthSubscription &&
        !user.currentMonthSubscription.isPremium
      )
    },
    hasBasicSubForPrevMonth(state) {
      const config = useRuntimeConfig()
      const featureLive = config.public.BASIC_AND_PREMIUM === '1'
      const user = state.data

      return !!(
        featureLive &&
        user &&
        user.previousMonthSubscription &&
        !user.previousMonthSubscription.isPremium
      )
    },
    hasPausedSubscription(state) {
      return !!state.data?.pauseSubscription
    },
  },
  mutations: {
    setUser(state, data) {
      state.data = data
    },
    setUserPhoto(state, photo) {
      state.data = {
        ...state.data,
        photo,
      }
    },
  },
  actions: {
    async updateUserData({ commit }) {
      const { data, getSession } = useAuth()
      await getSession()

      commit('setUser', data.value)
    },
  },
}
