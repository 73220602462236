import { $fetchApi } from '~/composables/useFetchApi'

export const blog = {
  namespaced: true,
  state: () => ({
    blogPostDetails: {},
    blogPosts: {},
  }),
  getters: {
    blogPostDetails(state) {
      return state.blogPostDetails
    },
    blogPosts(state) {
      return state.blogPosts.items
    },
    totalPages(state) {
      return state.blogPosts.pageCount
    },
    currentPage(state) {
      return state.blogPosts.page
    },
  },
  mutations: {
    setBlogPostDetails(state, blogPost) {
      state.blogPostDetails = blogPost
    },
    setBlogPosts(state, data) {
      state.blogPosts = data
    },
  },
  actions: {
    async getBlogPost({ commit, state }, slug) {
      if (state.blogPostDetails.slug === slug) return state.blogPostDetails

      const currentPagePost = state.blogPosts.items?.find(
        (post) => post.slug === slug,
      )

      if (currentPagePost) {
        commit('setBlogPostDetails', currentPagePost)
        return currentPagePost
      }

      const response = await $fetchApi(`/mediacontent?slug=${slug}`)

      commit('setBlogPostDetails', response)
      return response
    },
    async getBlogPostsList({ commit, state }, pageIndex = 1) {
      if (state.blogPosts.page === parseInt(pageIndex)) return

      const response = await $fetchApi(
        `/mediacontent/blogposts?published=true&includeInOverview=true&pageSize=6&page=${pageIndex}`,
      )

      commit('setBlogPosts', response)

      return response
    },
  },
}
