export const drawer = {
  state: () => ({
    drawerOptions: {},
    isDrawerShown: false,
  }),
  getters: {
    drawerOptions(state) {
      return state.drawerOptions
    },
    isDrawerShown(state) {
      return state.isDrawerShown
    },
  },
  mutations: {
    setDrawerState: (state, isShown) => {
      state.isDrawerShown = isShown
    },
    setDrawerOptions: (state, opt) => {
      state.drawerOptions = opt
    },
  },
  actions: {
    updateDrawerState({ commit }, params) {
      commit('setDrawerState', params.isDrawerShown)
      if (params.drawerOptions) {
        commit('setDrawerOptions', params.drawerOptions)
      }
    },
  },
}
